$(function () {
    $("nav a[href^='#']")
        .not(".jq_open_support")
        .on("click", function (e) {
            e.preventDefault();

            var aux = $(this).attr("href");
            var factor = $(window).width() > 768 ? 200 : 200;

            $("nav").removeClass("open");
            $("html, body").animate(
                {
                    scrollTop: $(aux).offset().top - factor,
                },
                500
            );
        });

    $(".jq_open_support").on("click", function (e) {
        e.preventDefault();
        var root = document.querySelector("#voiceflow-chat").shadowRoot;
        $(".vfrc-launcher", root).trigger("click");
        changeStuff();
    });

    $(window).on("scroll", function () {
        if ($(this).scrollTop() > 100) {
            $("header").addClass("scrolled");
        } else {
            $("header").removeClass("scrolled");
        }
    });

    $(".jq_menu_open").on("click", function (e) {
        e.preventDefault();
        $("nav").addClass("open");
    });
    $(".jq_menu_close").on("click", function (e) {
        e.preventDefault();
        $("nav").removeClass("open");
    });

    function changeStuff() {
        if (document.querySelector("#voiceflow-chat").shadowRoot == null) {
            var root = $("#voiceflow-chat");
            $(".vfrc-header--title", root).text(bot_title);
        } else {
            var root = document.querySelector("#voiceflow-chat").shadowRoot;
            $(".vfrc-header--title", root).text(bot_title);
            var sheet = new CSSStyleSheet();
            sheet.replaceSync(`.c-iCDrnV { background:${bot_color}!important; }`);
            root.adoptedStyleSheets.push(sheet);
        }
    }

    var cenas = setInterval(function () {
        if (document.querySelector("#voiceflow-chat")) {
            // console.log();
            var root = document.querySelector("#voiceflow-chat").shadowRoot;
            $(".vfrc-launcher", root).on("click", function () {
                changeStuff();
            });
            clearInterval(cenas);
        }
    }, 1000);

    $("#submitBtn").on("click", function () {
        $("#contactForm").addClass("loading");
        var formData = $("#contactForm").serialize();
        // console.log(formData);
        let url = window.location.href;
        // console.log(url);
        const frExists = url.includes('/fr/');
        if (frExists) {
            url = '../../contact.php';
        } else {
            url = '../contact.php';
        }

        $.ajax({
            type: "POST",
            url: 'https://app.digitalrewards.online/api/sending-emails/contact-us',// url,//"contact.php",
            data: formData,
            success: function (response) {
                // console.log(response);
                alert('Message has been sent successfully!');
                $("#contactForm").removeClass("loading");
                $("#contactForm input,#contactForm textarea").val("");
            },
            error: function (error) {
                // console.log('Please fill in all the inputs.');
                alert('Please fill in all the inputs.')
                $("#contactForm").removeClass("loading");
            }
        });
    });


});

(function (d, t) {
    var v = d.createElement(t),
        s = d.getElementsByTagName(t)[0];
    v.onload = function () {
        window.voiceflow.chat.load({
            verify: {projectID: $(".lang-fr").length > 0 ? bot_id_fr : bot_id},
            url: "https://general-runtime.voiceflow.com",
            versionID: "production",
        });
    };
    v.src = "https://cdn.voiceflow.com/widget/bundle.mjs";
    v.type = "text/javascript";
    s.parentNode.insertBefore(v, s);
})(document, "script");

